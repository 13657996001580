@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(fonts/proximanova-regular-webfont.woff2) format('woff2'),
    url(fonts/proximanova-regular-webfont.woff) format('woff'),
    url(fonts/proximanova-regular-webfont.ttf) format('ttf');
}
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    src: url(fonts/proximanova-semibold-webfont.woff2) format('woff2'),
         url(fonts/proximanova-semibold-webfont.woff) format('woff'),
         url(fonts/proximanova-semibold-webfont.ttf) format('ttf');
}
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    src: url(fonts/proximanova-bold-webfont.woff2) format('woff2'),
    url(fonts/proximanova-bold-webfont.woff) format('woff'),
    url(fonts/proximanova-bold-webfont.ttf) format('ttf');
}
