@import "fonts";
@import "colors";

$fontFamily: "Proxima Nova", sans-serif;

body {
    font-family: $fontFamily;
    font-size: 15px;
    color: $foreground;
    background-color: $background;
}
html, body {
    height: 100%;
}
.popup-ct {
    position: relative;
}
svg {
    flex-shrink: 0;
}
a {
    border-bottom: 1px solid;
    padding-bottom: 3px;

    &:hover {
        border-bottom: 0;
    }
}
#app {
    height: 100%;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
img {
    image-rendering: -webkit-optimize-contrast;
}
::selection {
  //  background-color: $light-gray;
}
