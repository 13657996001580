.thank-you
{
    .message {
        max-width: 600px;
        margin: 80px auto;

        .msg-title
        {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .msg-content {
            font-size: 18px;
            line-height: 24px;
        }
    }
}
