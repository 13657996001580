$blue: #0B2D4E;
$red: #E54256;
$green:  #3BAD7D;
$light-gray: #e1e2e4;
$foreground: #181819;
$dark-gray: $foreground;
$yellow: #FBAE3E;
$orange: #F46845;

$background: #F5F5F5;
$footerHeight: 40px;
