@import "src/app/styles/colors";
@import "src/app/styles/constants";

.remind-me
{
    .message
    {
        max-width: 600px;
        margin: 80px auto;

        @media (max-width: $phoneSize) {
            margin: 60px auto;
        }

        .msg-title
        {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 25px;
        }

        .msg-content {
            font-size: 16px;
            line-height: 24px;
        }

        .email-form
        {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: $phoneSize) {
                flex-direction: column;
            }

            .email {
                height: 42px;
                border-radius: 21px;
                border: 1px solid #e0e0e4;
                padding-left: 25px;
                max-width: 400px;
                margin-right: 20px;

                @media (max-width: $phoneSize) {
                    margin-right: 0;
                    margin-bottom: 20px;
                    height: 48px;
                }
            }

            .btn-remind-me {
                border: none !important;
                background-color: $green;
                color: #ffffff;
                padding: 0 30px;
                align-items: center;
                justify-content: center;
                border-radius: 21px;
                font-size: 15px;
                width: 150px;
                height: 42px;
                cursor: pointer;

                @media (max-width: $phoneSize) {
                    width: 100%;
                    height: 48px;
                }

                &:hover {
                    opacity: 0.85;
                }

                &:active {
                    opacity: 0.6;
                }
            }

            input {
                font-size: 15px;
            }
            input::-webkit-input-placeholder {
                font-size: 15px;
                line-height: 42px;
                @media (max-width: $phoneSize) {
                    width: 100%;
                    line-height: 48px;
                }
            }
        }
    }
}
