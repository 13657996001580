@import "src/app/styles/constants";
@import "src/app/styles/colors";

.page {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.page-header {
    width: 100%;
    height: 70px;
    background-color: $dark-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.page-footer
{
    text-align: center;
    margin-top: auto;
    padding-bottom: 30px;
}
.container {

    @media (max-width: $laptopSize) {
        margin: 50px auto;
    }

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media (max-width: $phoneSize) {
        margin: 30px auto;
    }

    padding: 0 25px;
    width: 1000px;
    margin: 80px auto;
}
.title
{
    font-size: 40px;
    text-align: center;

    @media (max-width: $laptopSize) {
        line-height: 40px;
        font-size: 35px;
    }

    @media (max-width: $phoneSize) {
        line-height: 40px;
        font-size: 30px;
    }

    .autopilot {
        background: $orange;
        display: inline-block;
        margin: 0 15px;
        border-radius: 10px;
        color: #ffffff;
        padding: 0 20px;
        line-height: 56px;

        @media (max-width: $phoneSize) {
            line-height: 40px;
            margin: 0 10px;
            padding: 0 15px;
        }
    }
}
