@import "src/app/styles/colors";
@import "src/app/styles/constants";

.home
{
    .steps {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @media (max-width: $laptopSize) {
            margin-top: 50px;
            padding: 0 20px;
        }

        @media (max-width: $phoneSize) {
            margin-top: 40px;
            padding: 0;
        }

        .step {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            @media (max-width: $phoneSize) {
                margin-bottom: 20px;
            }

            .step-no {
                width: 56px;
                height: 56px;
                border-radius: 28px;
                background: $dark-gray;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 34px;
                line-height: 70px;
                padding-top: 5px;
                margin-right: 30px;
                flex-shrink: 0;

                @media (max-width: $phoneSize) {
                    width: 50px;
                    height: 50px;
                    border-radius: 25px;
                    font-size: 25px;
                    padding-top: 3px;
                }
            }

            .step-content {
                display: flex;
                flex-direction: column;
                padding-top: 5px;

                @media (max-width: $phoneSize) {
                    padding-top: 2px;
                }

                .step-title {
                    font-weight: 600;
                    font-size: 25px;
                    color: $foreground;
                    line-height: 30px;

                    @media (max-width: $phoneSize) {
                        font-size: 20px;
                        line-height: 23px;
                        margin-bottom: 5px;
                    }
                }

                .step-desc {
                    margin-left: 2px;
                    font-size: 16px;
                    color: darken($foreground, 5%);

                    @media (max-width: $phoneSize) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .actions {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        .btn-signup {
            cursor: pointer;
            border: none!important;
            background-color: $green;
            color: #ffffff;
            height: 56px;
            padding: 0 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 28px;
            font-size: 17px;
            margin-top: 70px;

            &:hover {
                opacity: 0.85;
            }
            &:active {
                opacity: 0.6;
            }
        }
        .arrow {
            position: absolute;
            margin-right: -50px;
            top: 0;
            height: 50px;
        }
    }
    .companies-title {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;

        @media (max-width: $phoneSize) {
            margin-top: 70px;
        }
    }
    .companies-list {
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        flex-direction: row;
        flex-basis: 0;
        padding-bottom: 50px;

        img {
            margin: 0 15px;
        }

        .linkedin {
            height: 80px;
        }
        .upwork {
            height: 70px;
        }
        .problogger {
            height: 37px;
            @media (max-width: $phoneSize) {
                margin-bottom: 30px;
            }
        }
        .flexjobs {
            height: 50px;
        }
        .wic {
            @media (max-width: $tabletSize) {
                display: none;
            }
            @media (max-width: $phoneSize) {
                display: block;
            }
            height: 49px;
        }
    }
}
